import React from "react";
import { useLocation } from "react-router-dom";

import Branding from "../Branding";
import RGL, { WidthProvider } from "react-grid-layout";
import { ReactGridLayout as RGLEditor } from "../../pages/editor/Editor";
import { getPlaceholderArea } from "./ScreenActions";
import ScreenContext from "./ScreenContext";
import AreaComponent from "./AreaComponent";
import "./Screen.css";

const findOrCreateArea = (areas, id) => {
  let area = areas.find((area) => area.id === id);
  if (!area) area = getPlaceholderArea(id); //TODO: ask what the advantage of separating this is

  return area;
};

const Screen = (props) => {
  const { onAreaChange, onBrandingChange, uploadImage, editMode } = props;
  const ReactGridLayout = editMode ? RGLEditor : WidthProvider(RGL);
  const heightFromScreen = (brandingSetting) => {
    /* Math explanation: layout is separated into 6 rows, each with a padding of 20 
    in between and on each end (7 times so 140) and the branding area has a height of 125 */
    return brandingSetting
      ? (window.innerHeight - 265) / 6
      : (window.innerHeight - 140) / 6;
  };

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const hideBranding = (urlParams.get("hideBranding"));

  return (
    <ScreenContext.Consumer>
      {({
        screenId,
        layout,
        areas,
        theme,
        editMode,
        background,
        socialMediaColor,
        brandingSetting,
        storeId,
        fontOverrides,
        taxData,
        scrollOverride,
      }) => (
        <div
          style={{
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
            // height: orientation === "H" ? "1080px" : "1920px",
            height: window.innerHeight,
          }}
        >
          {!hideBranding && (
            <Branding
              uploadImage={uploadImage}
              socialMediaColor={socialMediaColor}
              onBrandingChange={onBrandingChange}
            />
          )}
          <ReactGridLayout
            className="layout"
            isDraggable={false}
            autoSize={true}
            cols={3}
            layout={layout.value}
            margin={[20, 20]}
            rowHeight={heightFromScreen(brandingSetting)}
          >
            {layout.value.map((v) => (
              <div key={v.i} style={{ border: "none" }}>
                <AreaComponent
                  area={findOrCreateArea(areas, v.i)}
                  theme={theme}
                  storeId={storeId}
                  screenId={screenId}
                  onAreaChange={onAreaChange}
                  editMode={editMode}
                  fontOverrides={fontOverrides}
                  taxData={taxData}
                  scrollOverride={scrollOverride}
                />
              </div>
            ))}
          </ReactGridLayout>
        </div>
      )}
    </ScreenContext.Consumer>
  );
};

export default Screen;
