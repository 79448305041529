// import edible from "./edibles";
// import extract from "./extracts";
// import flower from "./flower";
// import joint from "./joints";
// import vape from "./vapes";
import columns from "./columns";

// const map = {
//   edible,
//   extract,
//   flower,
//   joint,
//   vape,
// };

const factory = (area) => {
  const cols = columns.filter((column) =>
    !!area.columns
      ? area.columns.includes(column.field)
      : column.default.includes(area.category)
  );
  return { columns: cols };
};

export default factory;
