import _ from 'lodash';

const inventoryHelper = {

  /**
   * @description Takes in an inventory list and does some filtering based on query-string params
   * @param {Array} data - full inventory data
   * @param {object} config - config object
   * @param {object} config.urlParams - object of the query-string params
   * @returns {string} propName - prop name for iconSets
   */
  qsFilter: function (data, config) {
    let myData = data;
    console.log(myData);

    const urlParams = config.urlParams || {};

    const filterBy = urlParams.get("filterBy");
    const filter = urlParams.get("filter");
    const excludeBy = urlParams.get("excludeBy");
    const exclude = urlParams.get("exclude");

    const limit = urlParams.get("limit") || 9999;
    const limitBy = urlParams.get("limitBy");
    const limitBySort = urlParams.get("limitBySort");
    const limitBySortDirection = urlParams.get("limitBySortDirection") || "asc";

    // if we're given a filterBy and filter then do that first
    // possible filters - Brand, Category, Dominance, ProductName, Strain or Type
    if (filterBy && filter) {
      const filterTerms = filter.split(",");

      // now we want to pull inventory where the defined property includes any of the filter terms
      myData = myData.filter((item) => {
        return filterTerms.some((term) => {
          return item[filterBy] ? item[filterBy].toLowerCase().includes(term.toLowerCase()) : false;
        });
      });
    }

    if (excludeBy && exclude) {
      const excludeTerms = exclude.split(",");

      // now we want to pull inventory where the defined property includes any of the filter terms
      myData = myData.filter((item) => {
        return excludeTerms.some((term) => {
          return item[excludeBy]
            ? !item[excludeBy].toLowerCase().includes(term.toLowerCase())
            : false;
        });
      });
    }

    // if we're given a sort and direction then do that first
    if (limitBySort) {
      myData = _.orderBy(myData, [limitBySort], [limitBySortDirection]);
    }

    // if we're not given a limitBy then just limit the whole list
    if (!limitBy) {
      myData = myData.slice(0, limit);
    } else if (limitBy) {
      // if we do have a limit by then go through the list and limit each group
      myData = Object.values(
        myData.reduce((acc, item) => {
          if (!acc[item[limitBy]]) {
            acc[item[limitBy]] = [];
          }
          if (acc[item[limitBy]].length < limit) {
            acc[item[limitBy]].push(item);
          }
          return acc;
        }, {})
      ).flat();
    }

    return myData;
  },

};

export default inventoryHelper;
