import React from 'react';
import emoji from "emoji-dictionary";

// full emoji list
// https://unicode.org/emoji/charts/full-emoji-list.html#2764

import iconSets from "../icons/icons";

let dominanceStyle = {
  alignItems: "center",
  display: 'flex',
  flexWrap: 'nowrap',
};

const dominanceHelper = {
  iconStyle: function (fontSize = 20) {
    return {
      height: `${1.5 * fontSize}px`,
      width: `${1.5 * fontSize}px`,
      paddingRight: "10px",
    };
  },
  emojiStyle: function (fontSize = 30) {
    return {
      fontSize: `${fontSize}px`,
      paddingRight: "10px",
      marginTop: '2px'
    };
  },

  /**
   * @description Takes in Dominance value and returns the propName to lookup on iconSets
   * @param {string} value - selected Dominance value
   * @returns {string} propName - prop name for iconSets
   */
  getProp: function (value) {
    const myVal = (value || "").toUpperCase();
    let propName = "";

    if (myVal === "SAT/HY" || myVal === "SATIVA-HYBRID") {
      propName = "sativaDom";
    } else if (myVal === "IND/HY" || myVal === "INDICA-HYBRID") {
      propName = "indicaDom";
    } else if (myVal === "DELTA-8") {
      propName = "delta8";
    } else if (myVal === "1 TO 1") {
      propName = "oneToOne";
    } else {
      propName = myVal.toLowerCase();
    }

    return propName;
  },

  /**
   * @description Takes in args and returns div for dominance icon or emoji
   * @param {object} args
   * @param {string} args.value - selected dominance value
   * @param {string} args.fontOverrides
   * @param {object} args.style
   * @param {object} args.settings
   * @returns {object} - dominance icon or emoji
   */
  getDominance: function (args) {
    const { settings } = args;

    let selectedIcon;
    if (settings.dominanceIcon === true) {
      selectedIcon = "Circle Gradients";
    } else {
      selectedIcon = settings.dominanceIcon;
    }

    const myIconSet = iconSets.find((x) => x.name === selectedIcon);
    //console.log(`selectedIcon: ${selectedIcon}`);
    //console.log(myIconSet);

    if (myIconSet) {
      if (myIconSet.setType === "emoji") {
        return this.getEmoji({ ...args, selectedIcon });
      } else {
        return this.getIcon({ ...args, selectedIcon });
      }
    } else {
      return null;
    }
  },

  /**
   * @description Takes in some params and returns a div to render Dominance icon
   * @param {object} args
   * @param {string} args.value - selected dominance value
   * @param {string} args.fontOverrides
   * @param {object} args.style
   * @param {object} args.settings
   * @returns {object} dominance icon div
   */
  getIcon: function (args) {
    let { value, fontOverrides, style, selectedIcon } = args;

    let fontSize;
    if (fontOverrides && fontOverrides.dataSize !== "Default") {
      fontSize = fontOverrides.dataSize;
    }

    let myIcon;
    const iconSet = iconSets.find((x) => x.name === selectedIcon);
    if (iconSet) {
      myIcon = iconSet[this.getProp(value)];
    }

    if (value && myIcon) {
      return (
        <div
          style={{
            ...dominanceStyle,
            justifyContent: style.textAlign ? style.textAlign : null,
          }}
        >
          <img
            src={myIcon}
            alt={`${value} icon`}
            style={this.iconStyle(fontSize)}
          ></img>
          {!selectedIcon.includes("no text") ? value : null}
        </div>
      );
    }
    return null;
  },

  /**
   * @description Takes in some params and returns a div to render emoji icon
   * @param {object} args
   * @param {string} args.value - selected dominance value
   * @param {string} args.fontOverrides
   * @param {object} args.style
   * @param {object} args.settings
   * @returns {object} dominance icon div
   */
  getEmoji: function (args) {
    let { value, fontOverrides, style, selectedIcon } = args;

    let fontSize;
    if (fontOverrides && fontOverrides.dataSize !== "Default") {
      fontSize = fontOverrides.dataSize;
    }

    let myIcon;
    const iconSet = iconSets.find((x) => x.name === selectedIcon);
    if (iconSet) {
      myIcon = iconSet[this.getProp(value)];
    }

    if (value && myIcon) {
      return (
        <div
          style={{
            ...dominanceStyle,
            justifyContent: style.textAlign === "center" ? "center" : null,
          }}
        >
          <span style={this.emojiStyle(fontSize)}>
            {emoji.getUnicode(myIcon)}
          </span>
          {!selectedIcon.includes("no text") ? value : null}
        </div>
      );
    }
    return null;
  },
};

export default dominanceHelper;
