import React, { useEffect, useState } from "react";
import axios from "axios";
import * as appFunc from "../../App.Func";
import EditIcon from "@material-ui/icons/Edit";

import columns from "../../tmpl/menus/columns";
import useInventory from "./useInventory";
import AreaContentRenderer from "./AreaContentRenderer";
import EditModal from "./EditModal";

const AreaComponent = (props) => {
    const {
        area,
        theme,
        editMode,
        onAreaChange,
        storeId,
        screenId,
        fontOverrides,
        taxData,
        scrollOverride,
    } = props;

    // Dialog State
    const [openSettings, setOpenSettings] = useState(false);

    // Area setting
    const [selectedCategory, setSelectedCategory] = useState(
        area.category ? (typeof area.category === "string" ? [area.category] : area.category) : []
    );
    const [selectedColumns, setSelectedColumns] = useState(
        area.columns
            ? area.columns.map((column) => column.field)
            : area.category
            ? columns
                  .filter((column) => column.default.includes(area.category))
                  .map((column) => column.field)
            : []
    );

    const [typeFilter, setTypeFilter] = useState(area.typeFilter ? area.typeFilter : []);
    const [availableType, setAvailableType] = useState(null);
    const [inventoryData, setInventoryData] = useState();

    const fetchInventory = useInventory(screenId, storeId, selectedCategory, setInventoryData);

    const retrieveType = async (categories) => {
        try {
            const { data: typesData } = await axios.get(
                `${appFunc.getApiUrl()}/Inventory/${storeId}/Types`,
                {
                    params: {
                        categories,
                    },
                }
            );
            setAvailableType(typesData);
            const selectedTypes = typeFilter.reduce((acc, type) => {
              const item = typesData.find(el => el.Type === type);
              if(item){
                return [...acc,type]
              }
              return acc;
            },[])
            setTypeFilter(selectedTypes);
        } catch (err) {}
    };

    useEffect(() => {
        if (selectedCategory && availableType === null && editMode) {
            retrieveType(selectedCategory);
        }
    }, [selectedCategory, availableType, editMode]);

    // Handles open and close for dialog
    const handleClickOpen = () => {
        setOpenSettings(true);
    };
    const handleCloseSettings = () => {
        setOpenSettings(false);
    };

    return (
        <div key={area.id} style={{ height: "100%", width: "100%" }}>
            <EditIcon
                style={{
                    color: "#ff0dbf", // This color works for now for visibility
                    mixBlendMode: "difference",
                    top: "0",
                    right: "0",
                    cursor: "pointer",
                    zIndex: "10",
                    position: "absolute",
                    display: editMode ? "" : "none",
                }}
                onClick={handleClickOpen}
            />
            {editMode && (
                <EditModal
                    open={openSettings}
                    onClose={handleCloseSettings}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    area={area}
                    taxData={taxData}
                    onAreaChange={onAreaChange}
                    availableType={availableType}
                    setSelectedColumns={setSelectedColumns}
                    retrieveType={retrieveType}
                    selectedColumns={selectedColumns}
                    fetchInventory={fetchInventory}
                />
            )}
            {inventoryData ? (
                <AreaContentRenderer
                    area={area}
                    data={inventoryData}
                    theme={theme}
                    refresh={fetchInventory}
                    fontOverrides={fontOverrides}
                    taxData={taxData}
                    scrollOverride={scrollOverride}
                />
            ) : (
                <div />
            )}
        </div>
    );
};

export default AreaComponent;
