
export const toFixedDecimalsDown = (value, countOfNumbers) => {
    const [intPart, floatPart = ''] = `${value}`.replace(',', '.').split('.');
    const floatNumbers = floatPart.split('');
    
    const newFloatPart = new Array(countOfNumbers).fill('0').map((item, index) => {
        const existingFloatNumber = floatNumbers[index];
        if(existingFloatNumber){
            return existingFloatNumber;
        }
        return item
    }).join('');

    return countOfNumbers === 0 ? `${intPart}` :`${intPart}.${newFloatPart}`
}