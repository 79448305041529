import React from "react";
import { filterFunc, pricePoints, sortFunc } from "./service";
import Menu from "../Menu";
import gsLogo from "../../assets/img/icons/gsLogo.png";

const AreaContentRenderer = ({
    area,
    data,
    theme,
    refresh,
    fontOverrides,
    taxData,
    scrollOverride,
}) => {
    if (!area) {
        return null;
    }

    // console.log(area, data, theme);
    // data.map(x => console.log({ name: x.ProductName, dom: x.Dominance }));

    if (area.type === "menu") {
        data = data.filter((datum) => datum.Quantity >= 1);

        // Checks if filter arrays are present
        if (area.domFilter || area.typeFilter) {
            // 3 Checks for different states of empty/non-empty filter arrays
            if (area.domFilter.length > 0) {
              // adjust the dom filters to make sure the hybrids are covered
              if (area.domFilter.includes('Sat/Hy') && !area.domFilter.includes('Sativa-Hybrid')) {
                area.domFilter.push('Sativa-Hybrid');
              }
              if (area.domFilter.includes('Ind/Hy') && !area.domFilter.includes('Indica-Hybrid')) {
                area.domFilter.push("Indica-Hybrid");
              }

              data = data.filter((datum) =>
                area.domFilter
                  .map((filter) => filter.toUpperCase())
                  .includes(
                    datum.Dominance
                      ? datum.Dominance.toUpperCase()
                      : datum.Dominance
                  )
              );
            }
            // console.log(data);

            if (area.typeFilter.length > 0) {
                const typeFilters = area.typeFilter.map((filter) => filter.toLowerCase());
                data = data.filter((datum) => typeFilters.includes(datum.Type.toLowerCase()));
            }
        }

        if (area.priceFilter && area.priceFilter.length > 0) {
            data = data.filter((datum) => {
                return filterFunc(
                    datum,
                    area.priceFilter[0],
                    area.priceFilterRestriction.length === 0
                        ? pricePoints
                        : area.priceFilterRestriction,
                    taxData
                );
            });
        }

        if (area.sort && area.sort.length > 0) {
            data = data.sort((a, b) => sortFunc(a, b, area.sort));
        } else if (area.category === "Flower" || area.category.includes("Flower")) {
            data = data.sort((a, b) => sortFunc(a, b, ["Dominance", "Name"]));
        } else if (area.category !== "Flower" || !area.category.includes("Flower")) {
            data = data.sort((a, b) => sortFunc(a, b, ["Name"]));
        }

        if (area.category === "Flower" || area.category.includes("Flower")) {
            if (data[0] && data[0].StoreOwnerId === 5096) {
                //! Remove when data for EDEN cleaned up
                data = data.map((datum) => {
                    return {
                        ...datum,
                        ProductName: datum.ProductName.replace(/\s\|\sLoose/g, ""),
                    };
                });
                if (data[0].StoreId === 6071) {
                    data = data.filter((datum) => datum.Quantity >= 5);
                } else {
                    data = data.filter((datum) => datum.Quantity >= 15);
                }
            } else if (data[0] && data[0].StoreId === 6137) {
                //! Holistic Healing
                data = data.filter((datum) => datum.Quantity >= 3.5);
            }
        }
        //! Remove when ProductName cleaned for OGMedicinals 67
        if (data[0] && data[0].StoreId === 67) {
            data = data.map((datum) => {
                return {
                    ...datum,
                    ProductName: datum.ProductName.replace(/MEDICAL-\s|MEDICAL\s-\s|MEDICAL-/g, ""),
                };
            });
        }
        //! Remove when quantity filter feature complete (Fruit of the Earth)
        if (data[0] && data[0].StoreId === 6227) {
            data = data.filter((datum) => datum.Quantity >= 2);
        }
        //! KindFarma
        if (
            data[0] &&
            data[0].StoreId === 6252 &&
            !(area.category == "Merchandise" || area.category.includes("Merchandise"))
        ) {
            data = data.filter((datum) => datum.Quantity > 5);
        }
        //! Garden Remedies
        if (
            data[0] &&
            (data[0].StoreId === 6210 || data[0].StoreId === 6203 || data[0].StoreId === 6205)
        ) {
            data = data.filter((datum) => datum.Quantity > 2);
        }
        //! True Dank
        if (data[0] && data[0].StoreId === 6208) {
            data = data.filter((datum) => datum.Quantity > 10);
        }
        //! The Healing Tree
        if (data[0] && data[0].StoreId === 6231) {
            if (area.category == "Flower" || area.category.includes("Flower")) {
                data = data.filter((datum) => datum.Quantity > 16);
            }
            if (area.category == "Extract" || area.category.includes("Extract")) {
                data = data.filter((datum) => datum.Quantity > 5);
            }
        }
        //! Briargate Wellness flower
        if (
            data[0] &&
            data[0].StoreId === 6154 &&
            (area.category == "Flower" || area.category.includes("Flower"))
        ) {
            data = data.filter((datum) => datum.Quantity > 20);
        }
        //! Sandia Botanicals - Las Cruces
        if (data[0] && data[0].StoreId === 6280) {
            data = data.filter((datum) => datum.Quantity > 5);
        }
        return (
            <Menu
                {...area}
                initialData={data}
                theme={theme}
                refresh={refresh}
                fontOverrides={fontOverrides}
                taxData={taxData}
                scrollOverride={scrollOverride}
            />
        );
    }
    if (area.type === "ad" || area.type === "content") {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100%",
                    alignItems: "center",
                }}
            >
                <img src={gsLogo} style={{ maxHeight: "200px" }}></img>
            </div>
        );
    }
    if (area.type === "placeholder") {
        return <div></div>;
    }
    //TODO: add cases for other types of areas
    throw Error(`Area type ${area.type} unknown`);
};

export default AreaContentRenderer;
